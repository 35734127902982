import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom/client';
import StockMoodVisualization from './StockMoodVisualization';
import PersonalInfo from './PersonalInfo';
import GoatCounter from './GoatCounter';
import './index.css';
import reportWebVitals from './reportWebVitals';

// Constants
const DAY_TITLE = '☀️ good day 😎';
const NIGHT_TITLE = '🌙 good evening ✨';
const DAY_START_HOUR = 6;
const DAY_END_HOUR = 18;
const THEME_CHECK_INTERVAL = 60000; // 1 minute

const App = () => {
  const [isDaytime, setIsDaytime] = useState(true);

  // Function to determine if it's daytime based on the current hour
  const isDaytimeByHour = useCallback(() => {
    const hour = new Date().getHours();
    return hour >= DAY_START_HOUR && hour < DAY_END_HOUR;
  }, []);

  // Function to update the document title
  const updateDocumentTitle = useCallback((isDaytime) => {
    document.title = isDaytime ? DAY_TITLE : NIGHT_TITLE;
  }, []);

  // Function to update the theme based on system preference or time
  const updateTheme = useCallback(() => {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme)').matches) {
      const darkModeOn = window.matchMedia('(prefers-color-scheme: dark)').matches;
      setIsDaytime(!darkModeOn);
      updateDocumentTitle(!darkModeOn);
    } else {
      const daytime = isDaytimeByHour();
      setIsDaytime(daytime);
      updateDocumentTitle(daytime);
    }
  }, [isDaytimeByHour, updateDocumentTitle]);

  // Effect to set up theme listeners and interval checks
  useEffect(() => {
    updateTheme(); // Initial check

    const colorSchemeQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const themeChangeHandler = () => updateTheme();

    // Add event listener with compatibility check
    if (colorSchemeQuery.addEventListener) {
      colorSchemeQuery.addEventListener('change', themeChangeHandler);
    }

    // Set up interval for time-based checks
    const intervalId = setInterval(() => {
      if (!(window.matchMedia && window.matchMedia('(prefers-color-scheme)').matches)) {
        updateTheme();
      }
    }, THEME_CHECK_INTERVAL);

    // Cleanup function
    return () => {
      if (colorSchemeQuery.removeEventListener) {
        colorSchemeQuery.removeEventListener('change', themeChangeHandler);
      }
      clearInterval(intervalId);
    };
  }, [updateTheme]);

  // Effect to update body classes when isDaytime changes
  useEffect(() => {
    document.body.classList.toggle('day', isDaytime);
    document.body.classList.toggle('night', !isDaytime);
  }, [isDaytime]);

  return (
    <div className="container">
      <StockMoodVisualization isDaytime={isDaytime} />
      <PersonalInfo />
      <GoatCounter />
    </div>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

reportWebVitals();

export default App;