import React from 'react';
import './PersonalInfo.css';

const PersonalInfo = () => {
  const timelineData = [
    { year: 'Late 90s', event: 'The internet and I met, one of my first loves' },
    { year: 'Early 2010s', event: 'Graphic design, multimedia studies @ Northeastern University' },
    { year: '1 year', event: 'Frontend development @ The Boston Globe' },
    { year: '2 years', event: 'Design, frontend development @ Airbo' },
    { year: '3 years', event: 'Design, frontend development, management @ thoughtbot' },
    { year: '6+ years', event: 'Product design @ Iterable' }
  ];

  const contactInfo = [
    { medium: 'Mastodon', link: 'https://mastodon.social/@conchan', rel: 'me' },
    { medium: 'Bluesky', link: 'https://bsky.app/profile/conchan.site', rel: 'me' },
    { medium: 'Github', link: 'https://github.com/conchan', rel: 'me' },
    { medium: 'Dribbble', link: 'https://dribbble.com/conchan' },
    { medium: 'Email', link: 'mailto:hey@conchan.site' },
    { medium: 'PGP', link: 'pgp.txt' }
  ];

  return (
    <div
      className="personal-info"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <h1 className="name">Constance <br/>Chan</h1>
      <h3 className="description">A designer of this digital world</h3>
      <ul className="timeline">
        {timelineData.map((item, index) => (
          <li
            className="timeline-item"
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
          >
            <p>
              <strong>{item.year}:</strong> {item.event}
            </p>
          </li>
        ))}
      </ul>

      <ul className="contact-info">
        {contactInfo.map((item, index) => (
          <li
            className="contact-method"
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
          >
            <a rel={item.rel} href={item.link}>{item.medium}</a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PersonalInfo;